import { environment } from '../../environments/environment';
import { Grupo } from '../modelo/Grupo';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GruposService {
  apiUrl: string = `${environment.backendUrl}/grupos`;

  constructor(private http: HttpClient) { }

  getGrupos (){
    let searchParams = new HttpParams();

    return this.http.get<IApiResponse>(`${this.apiUrl}`,
    { params: searchParams });
  }

  getGruposAll (pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<IApiResponse>(`${this.apiUrl}`,
    { params: searchParams });
  }

  getHistorico(pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<any>(`${this.apiUrl}/aud`,
    {params: searchParams });
  }

  salvarGrupo(grupo: Grupo): Observable<any> {
    return this.http.post(`${this.apiUrl}`, grupo);
  }


  editarGrupo(grupo: Grupo): Observable<void>{
    return this.http.put<void>(`${this.apiUrl}/${grupo.id}`, grupo);
  }

  deletarGrupo(id: number): Observable<any> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

}
