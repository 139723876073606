import { Status } from "./Status"

export class Processamento {

    public uuid?: string
    public codigoCia?: string
    public usuarioResponsavel?: string
    public dataInicio?: Date
    public dataTermino?: Date
    public status?: Status
        
    }
    