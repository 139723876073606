export class ConfiguracaoCodigoEstabelecimentos {
    id?: number
    codCia?: string
    codEmpresa?: string
    empresaId?: number
    codigosEmpresaId?: number
    contribuinteId?: number
    codContribuinte?: string
    nomeContribuinte?: string
    codEstabelecimento?: string
    incluidoEm?: Date
    incluidoPor?: string
    alteradoEm?: Date
    alteradoPor?: string
}