<div style="display: flex; justify-content: space-between; align-items: center;">
  <div style="text-align: left;">
    <img src="../../assets/images/Avalara.png"
         class="Header__Main__Logo__Primary">
  </div>
  <div style="text-align: center;"><h2>CONVERSOR SAFX x TCO</h2></div>
  <div style="text-align: right;"><h6>Usuário: {{ usuarioLogado }}</h6></div>

</div>

<div class="menubar-container">
    <div class="card">
      <p-menubar [model]="items">
        <ng-template pTemplate="end">
          <span>
            <button
              type="button"
              pButton label="Sair"
              icon="pi pi-power-off"
              (click)="logout()"
              ></button>
          </span>
        </ng-template>
      </p-menubar>

    </div>

</div>

