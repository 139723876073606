import { AuthService } from '../core/auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { Grupo  } from '../modelo/Grupo';
import { GruposService } from './grupos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interval, throwError } from 'rxjs';
import { PageProps } from '../modelo/PageProps';
import { StatusGrupo  } from '../modelo/Grupo';

@Component({
  selector: 'app-manutencao-grupo',
  templateUrl: './manutencao-grupo.component.html',
  styleUrls: ['./manutencao-grupo.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class ManutencaoGrupoComponent {
  grupo: Grupo = new Grupo();
  grupos: Grupo[] = [];

  nome : string [] = [];
  GrupoPage: PageProps<Grupo> = {} as PageProps<Grupo>;

  selectedGrupos: Grupo[] = [];
  dialog: boolean = false;
  visible: boolean = false;

  dataDefault: Date = new Date();

  statusGrupo: any[] = [{ chave: '', valor: '' }];

  totalRecords!: number;
  firstPage = true;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 10;

  totalRecords2!: number;
  firstPage2 = true;
  totalPages2 = 0;
  pageNumber2 = 0;
  pageSize2 = 5;

  loading: boolean = true;
  submitted!: boolean;
  lifeMessageToast: number = 7000;

  constructor(
    private auth: AuthService,    
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private serviceGrupo: GruposService,
  ){}

  async ngOnInit() {
    await this.fetchContent(this.pageNumber, this.pageSize);

    interval(10000) // Atualiza a cada 5 segundos (5000 milissegundos)
    .subscribe(() => this.fetchContent(this.pageNumber, this.pageSize)
    );


    this.dialog = false;

    //this.getGrupos();

    for (const [values, keys] of Object.entries(StatusGrupo)) {
      this.statusGrupo.push({chave:keys, valor:values});
    }
  }

  public fetchContent(page: number, rows: number): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.serviceGrupo.getGruposAll(this.pageNumber, this.pageSize).subscribe({
      next: (payload) => {
        console.log('Retorno da tabela grupo:', payload.content);
        this.GrupoPage = payload;
        this.grupos = this.GrupoPage.content;
        this.totalRecords = this.GrupoPage.totalElements;
        this.totalPages = this.GrupoPage.totalPages;
        this.pageSize = this.GrupoPage.pageSize;
        this.pageNumber = this.GrupoPage.currentPage;
        this.firstPage = this.GrupoPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Grupos',
          detail: 'Não foi possível preencher a lista de grupos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  add() {
    this.grupo = new Grupo();
    this.visible = true;
  }
//abre dialog
openNew() {
  this.submitted = false;
  this.dialog = true;
}

//fecha dialog
hideDialog() {
  this.visible = false;
  this.submitted = false;
}
salvarGrupo() {
  this.pageNumber = 0;
  this.pageSize = 5;
  this.submitted = true;
  if (this.grupo.id) {
    try {
      this.serviceGrupo.editarGrupo(this.grupo)
      .pipe(
        catchError(error => {
            console.error('Erro ao salvar o grupo:', error);
            if (!error.error.error)
              this.messageService.add({
                severity: 'error',
                summary: 'Grupo',
                detail: 'Erro ao salvar grupo. ' + error.error,
                life: this.lifeMessageToast,
              });    
            return throwError(error);
        }))
      .subscribe((response) => {
        this.pageNumber = 0;
        this.pageSize = 5;
        console.log('entrei no if para atualizacao do grupo');
        this.fetchContent(this.pageNumber, this.pageNumber);
        this.messageService.add({
          severity: 'success',
          summary: 'Grupo',
          detail: 'Grupo atualizado com sucesso!',
          life: this.lifeMessageToast,
        });
      });
      this.visible = false;
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Grupo',
        detail: 'Não foi possível atualizar grupo!',
        life: this.lifeMessageToast,
      });
    }
  } else {
    try {
      console.log('salvar' + this.grupo);
      if (this.grupo.status != null && this.grupo.nome != null && this.grupo.email != null) {
        this.serviceGrupo.salvarGrupo(this.grupo)
        .pipe(
          catchError(error => {
              console.error('Erro ao salvar o grupo:', error);
              if (!error.error.error)
                this.messageService.add({
                  severity: 'error',
                  summary: 'Grupo',
                  detail: 'Erro ao salvar grupo. ' + error.error,
                  life: this.lifeMessageToast,
                });    
              return throwError(error);
          }))
          .subscribe((response) => {
          this.pageNumber = 0;
          this.pageSize = 5;
          this.fetchContent(this.pageNumber, this.pageSize);
          this.visible = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Grupo',
            detail: 'Grupo salvo com sucesso!',
            life: this.lifeMessageToast,
          });
        });
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Grupo',
          detail: 'Campos obrigatórios não preenchidos!',
          life: this.lifeMessageToast,
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Grupo',
        detail: 'Não foi possível salvar grupo!',
        life: this.lifeMessageToast,
      });
    }
  }
  this.pageNumber = 0;
  this.pageSize = 5;
  this.fetchContent(this.pageNumber, this.pageSize);
}

editarGrupo(grupo: Grupo) {
  this.pageNumber = 0;
  this.pageSize = 5;
  this.grupo = { ...grupo };
  this.dialog = true;
  this.visible = true;
  console.log('entrei no editar grupo', this.grupo);
}

deletarGrupo(id: number) {
  this.pageNumber = 0;
  this.pageSize = 5;
  console.log('cliquei para excluir grupo')
  this.confirmationService.confirm({
    //target: event.target,
    header: 'Confirmação',
    message: 'Tem certeza que deseja deletar o grupo de núnero?' + id,
    icon: 'pi pi-exclamation-triangle',
    blockScroll: true,
    dismissableMask: true,
    acceptLabel: 'Sim',
    rejectLabel: 'Não',
    acceptVisible: true,
    closeOnEscape: true,
    accept: () => {
        this.serviceGrupo
          .deletarGrupo(id)
          .toPromise()
          .then((response) => {
            console.log('deu erro excluir grupo')
            this.fetchContent(this.pageNumber, this.pageSize);
          });
      this.messageService.add({
        severity: 'success',
        summary: 'Grupo',
        detail: 'Grupos(os) deletado(s) com sucesso!',
        life: this.lifeMessageToast,
      });
    },
    reject: () => {
      this.messageService.add({
        severity: 'error',
        summary: 'Grupo',
        detail: 'Erro ao tentar deletar grupo!',
        life: this.lifeMessageToast,
      });
    }
  });
  this.fetchContent(this.pageNumber, this.pageSize);
}

}
