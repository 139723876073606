import { Injectable } from '@angular/core';
import { Projeto } from 'src/app/modelo/Projeto';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private data: any;

  setProjeto(projetoId?: number) {
    localStorage.setItem("projeto.id", String(projetoId));
  }

  getProjetoId(): String | null {
    return localStorage.getItem("projeto.id");
  }

  cleanProjetoId() {
    localStorage.removeItem("projeto.id");
  }

  setProcessamentoArquivo(processamentoId?: number) {
    localStorage.setItem("processamentoArquivo.id", String(processamentoId));
  }

  getProcessamentoArquivo(): String | null {
    return localStorage.getItem("processamentoArquivo.id");
  }

  cleanProcessamentoId() {
    localStorage.removeItem("processamentoArquivo.id");
  }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }
  setProcessamentoLista(processamentoId?: number) {
    localStorage.setItem("processamentoLista.id", String(processamentoId));
  }

  getProcessamentoLista(): String | null {
    return localStorage.getItem("processamentoLista.id");
  }

  cleanProcessamentoListaId() {
    localStorage.removeItem("processamentoLista.id");
  }

}
