import { AuthService } from '../core/auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Companhia } from '../modelo/Companhia';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfiguracaoCodigoEmpresas } from '../modelo/ConfiguracaoCodigoEmpresas';
import { ConfiguracaoCodigoEmpresasService } from './configuracao-codigo-empresas.service';
import { ConfiguracaoCodigoEstabelecimentos } from '../modelo/ConfiguracaoCodigoEstabelecimentos';
import { ConfiguracaoCodigoEstabelecimentosService } from '../configuracao-codigo-estabelecimentos/configuracao-codigo-estabelecimentos.service';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { PageProps } from '../modelo/PageProps';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-configuracao-codigo-empresas',
    templateUrl: './configuracao-codigo-empresas.component.html',
    styleUrls: ['./configuracao-codigo-empresas.component.css'],
    providers: [MessageService, ConfirmationService],
  })

  export class ConfiguracaoCodigoEmpresasComponent {

    dialog: boolean = false;
    empresa: ConfiguracaoCodigoEmpresas = new ConfiguracaoCodigoEmpresas();
    empresaPage: PageProps<ConfiguracaoCodigoEmpresas> = {} as PageProps<ConfiguracaoCodigoEmpresas>;
    empresas: ConfiguracaoCodigoEmpresas[] = [];

    estabelecimento: ConfiguracaoCodigoEstabelecimentos = new ConfiguracaoCodigoEstabelecimentos();
    estabelecimentoPage: PageProps<ConfiguracaoCodigoEstabelecimentos> = {} as PageProps<ConfiguracaoCodigoEstabelecimentos>;
    estabelecimentos: ConfiguracaoCodigoEstabelecimentos[] = [];

    firstPage = true;
    lifeMessageToast: number = 5000;
    listaCompanhias: Companhia[] = [];
    loading: boolean = false;
    pageNumber = 0;
    pageSize = 10;
    submitted!: boolean;
    totalPages = 0;
    totalRecords!: number;
    visible: boolean = false;
    
    constructor(
      private auth: AuthService,       
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private service: ConfiguracaoCodigoEmpresasService,
      private serviceEstabelecimento: ConfiguracaoCodigoEstabelecimentosService
    ) {}

    async ngOnInit() {
      this.loading = true;
      this.fetchContent(this.pageNumber, this.pageSize);  
      this.dialog = false;
      this.loading = false;
    }

    fetchContent(page: number, rows: number) {
      if (!this.auth.isAuthenticated())
        return;
      this.loading = true;
      this.empresas = [];
      this.service.getEmpresasAll(this.pageNumber, this.pageSize).subscribe({
        next: (payload) => {
          this.empresaPage = payload;
          this.empresas = this.empresaPage.content;
          this.totalRecords = this.empresaPage.totalElements;
          this.totalPages = this.empresaPage.totalPages;
          this.pageSize = this.empresaPage.pageSize;
          this.pageNumber = this.empresaPage.currentPage;
          this.firstPage = this.empresaPage.firstPage;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Códigos de Empresa',
            detail: 'Não foi possível preencher a lista de registros!',
            life: this.lifeMessageToast,
          });
        },
      });
      this.loading = false;
    }

    fetchContentEstabelecimento(page: number, rows: number) {
      if (!this.auth.isAuthenticated())
        return;
      this.loading = true;
      this.estabelecimentos = [];
      this.serviceEstabelecimento.getEstabelecimentosAll(this.empresa.id!, this.pageNumber, this.pageSize).subscribe({
        next: (payload) => {
          this.estabelecimentoPage = payload;
          this.estabelecimentos = this.estabelecimentoPage.content;
          this.totalRecords = this.estabelecimentoPage.totalElements;
          this.totalPages = this.estabelecimentoPage.totalPages;
          this.pageSize = this.estabelecimentoPage.pageSize;
          this.pageNumber = this.estabelecimentoPage.currentPage;
          this.firstPage = this.estabelecimentoPage.firstPage;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Códigos de Estabelecimentos',
            detail: 'Não foi possível preencher a lista de registros!',
            life: this.lifeMessageToast,
          });
        },
      });
      this.loading = false;
    }

    abreEstabelecimentos(dado: ConfiguracaoCodigoEmpresas) {      
      this.empresa = dado;
      this.fetchContentEstabelecimento(this.pageNumber, this.pageSize);
      this.visible = true;
    }

    remover(dado: ConfiguracaoCodigoEmpresas) {
      this.pageNumber = 0;
      this.pageSize = 5;
      this.confirmationService.confirm({
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro de Empresa',
        icon: 'pi pi-exclamation-triangle',
        blockScroll: true,
        dismissableMask: true,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        acceptVisible: true,
        closeOnEscape: true,
        accept: () => {
          this.service.remover(dado.id)
          .pipe(
            catchError(error => {
                if (!error.error.error)
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Códigos de Empresa',
                    detail: 'Erro ao remover código de empresa. ',
                    life: this.lifeMessageToast,
                  });    
                return throwError(error);
            }))
          .subscribe((response) => {
            this.fetchContent(this.pageNumber, this.pageSize);            
            this.pageNumber = 0;
            this.pageSize = 5;
            this.messageService.add({
              severity: 'success',
              summary: 'Códigos de Empresa',
              detail: 'Registro removido com sucesso!',
              life: this.lifeMessageToast,
            });
          });
          this.visible = false;
        }
      });
    }

    gravarEdicao() {
      this.service.editar(this.empresa)
      .pipe(
        catchError(error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Códigos de Empresa',
              detail: 'Erro ao salvar código de empresa. ',
              life: this.lifeMessageToast,
            });    
          return throwError(error);
        }))
      .subscribe((response) => {
        this.pageNumber = 0;
        this.pageSize = 5;
        this.visible = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Códigos de Empresa',
          detail: 'Registro atualizado com sucesso!',
          life: this.lifeMessageToast,
        });
      });
    }

    gravarInclusao() {
      this.service.salvar(this.empresa)
      .pipe(
        catchError(error => {
              this.messageService.add({
                severity: 'error',
                summary: 'Códigos de Empresa',
                detail: 'Erro ao salvar registro. ' + error.error,
                life: this.lifeMessageToast,
              });    
            return throwError(error);
        }))
        .subscribe((response) => {
        this.pageNumber = 0;
        this.pageSize = 5;
        this.visible = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Códigos de Empresa',
          detail: 'Registro salvo com sucesso!',
          life: this.lifeMessageToast,
        });
      });
    }

    salvar() {
      this.pageNumber = 0;
      this.pageSize = 5;
      this.submitted = true;
      try {
        if (this.empresa.id != 0)
          this.gravarEdicao();
        else
          this.gravarInclusao();
      }
      catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Códigos de Empresa',
          detail: 'Não foi possível salvar',
          life: this.lifeMessageToast,  
        });
      }
    }
    
    hideDialog() {
      this.visible = false;
      this.submitted = false;    
    }

    formatarData(data: Date): string {
      return formatDate(data, 'dd/MM/yyyy', 'en-US');
    }


    getToolTipInfo(dados: ConfiguracaoCodigoEmpresas): string {
      if (!dados.id)
        return "Companhia não configurada";
      let retorno = "Incluido por " + dados.incluidoPor + "\n(" + this.formatarData(dados.incluidoEm!) + ")";
      if (dados.alteradoEm)
        retorno = retorno + "\nAlterado por " + dados.alteradoPor + "\n(" + this.formatarData(dados.alteradoEm) + ")";
      return retorno;
    }

    onRowEditSave(registro: ConfiguracaoCodigoEmpresas) {
      let valido = true;

      this.empresa = registro;

      if (!this.empresa.codEmpresa) {
        valido = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Códigos de Empresa',
          detail: 'Código da Empresa é obrigatório e não foi preenchido.',
          life: this.lifeMessageToast});
      }

      if (!this.empresa.grupoContabil) {
        valido = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Códigos de Empresa',
          detail: 'Grupo Contábil é obrigatório e não foi preenchido.',
          life: this.lifeMessageToast});
      }

      if (!valido)
        return;
      this.salvar();
      this.fetchContent(this.pageNumber, this.pageSize);
    }

    onRowEditCancel() {
      this.fetchContent(this.pageNumber, this.pageSize);
    }

    onRowEditCancelEstab() {
      this.fetchContentEstabelecimento(this.pageNumber, this.pageSize);
    }

    gravarEdicaoEstab() {
      this.serviceEstabelecimento.editar(this.estabelecimento)
      .pipe(
        catchError(error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Códigos de Estabelecimento',
              detail: 'Erro ao salvar código de estabelecimento. ',
              life: this.lifeMessageToast,
            });    
          return throwError(error);
        }))
      .subscribe((response) => {
        this.fetchContentEstabelecimento(this.pageNumber, this.pageSize);

        this.messageService.add({
          severity: 'success',
          summary: 'Códigos de Estabelecimento',
          detail: 'Registro atualizado com sucesso!',
          life: this.lifeMessageToast,
        });
      });
    }

    gravarInclusaoEstab() {
      this.serviceEstabelecimento.salvar(this.estabelecimento)
      .pipe(
        catchError(error => {
              this.messageService.add({
                severity: 'error',
                summary: 'Códigos de Estabelecimento',
                detail: 'Erro ao salvar registro. ' + error.error,
                life: this.lifeMessageToast,
              });    
            return throwError(error);
        }))
        .subscribe((response) => {
          this.fetchContentEstabelecimento(this.pageNumber, this.pageSize);

          this.messageService.add({
            severity: 'success',
            summary: 'Códigos de Estabelecimento',
            detail: 'Registro salvo com sucesso!',
            life: this.lifeMessageToast,
        });
      });
    }

    salvarEstabelecimento() {
      this.submitted = true;
      if (this.estabelecimento.id != 0)
        this.gravarEdicaoEstab();
      else
        this.gravarInclusaoEstab();
    }

    onRowEditSaveEstab(estabelecimento: ConfiguracaoCodigoEstabelecimentos) {
      this.estabelecimento = estabelecimento;
      if (!this.estabelecimento.codEstabelecimento) {
        this.messageService.add({
          severity: 'error',
          summary: 'Códigos de Estabelecimento',
          detail: 'Código da Estabelecimento é obrigatório e não foi preenchido.',
          life: this.lifeMessageToast});
        return;
      }
      this.salvarEstabelecimento();
    }

    removerEstab(estabelecimento: ConfiguracaoCodigoEstabelecimentos) {
      this.pageNumber = 0;
      this.pageSize = 5;
      this.confirmationService.confirm({
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro de Estabelecimento',
        icon: 'pi pi-exclamation-triangle',
        blockScroll: true,
        dismissableMask: true,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        acceptVisible: true,
        closeOnEscape: true,
        accept: () => {
          this.serviceEstabelecimento.remover(estabelecimento.id)
          .pipe(
            catchError(error => {
                if (!error.error.error)
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Códigos de Estabelecimentos',
                    detail: 'Erro ao remover código de estelecimento. ',
                    life: this.lifeMessageToast,
                  });    
                return throwError(error);
            }))
          .subscribe((response) => {
            this.fetchContentEstabelecimento(this.pageNumber, this.pageSize);           
            this.pageNumber = 0;
            this.pageSize = 5;
            this.messageService.add({
              severity: 'success',
              summary: 'Códigos de Estabelecimento',
              detail: 'Registro removido com sucesso!',
              life: this.lifeMessageToast,
            });
          });
        }
      });
    }

  }
