export class ConfiguracaoCodigoEmpresas {
    id?: number
    codCia?: string
    nomeCia?: string
    codEmpresa?: string
    grupoContabil?: string
    incluidoEm?: Date
    incluidoPor?: string
    alteradoEm?: Date
    alteradoPor?: string
}