export class Grupo {
    [x: string]: any
    id?: number
    nome?: string
    cadastro?: Date
    status?: string
    site?: string
    email?: string
    telefone?: number
    clientId?: string
    clientSecret?: string
}

export enum StatusGrupo{
  ATIVO = "Ativo",
  INATIVO = "Inativo"
}
