import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../core/auth/service/auth.service';
import { Router } from '@angular/router';
import { MenuService } from './menu-bar.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {
  items: MenuItem[]= [];
  submenus: MenuItem[] = [];
  usuarioLogado: string | null;
  exibeRegras: boolean = true;
  gruposUsuario: string[] = [];

  constructor(
  private auth: AuthService,
  private router: Router,
  private service: MenuService,
  private cdr: ChangeDetectorRef) {
    this.usuarioLogado = this.auth.obterUsuarioLogado();
    this.gruposUsuario = this.auth.obterGruposUsuario();
  }

  async ngOnInit() {
    if ((window as any).CustomGPT) {
      (window as any).CustomGPT.init({
        p_id: "31181",
        p_key: "6361edb62694125796dc022b9bb4569e",
        width: "400px",
        height: "700px"
      });
      setTimeout(() => {
        const chatElement = document.querySelector('.customgpt-chat');
        if (chatElement) {
          chatElement.classList.add('customgpt-position');
        }
      }, 1000); // Ajuste o tempo conforme necessário
    } else {
      console.error('CustomGPT script not loaded!');
    }

    this.submenus.push({
      label: "Configurações",
      icon: 'pi pi-cog',
      items: [
        {
          label: "Códigos Empresa",
          icon: "pi pi-building",
          command: () => this.router.navigate(['codigo-empresa'])
        }
      ]
    });

    // Exibição condicional dos menus "Grupos" e "Usuários"
    if (this.usuarioLogado === 'admin' || this.gruposUsuario.includes('Avalara') || this.gruposUsuario.includes('Vetor')) {

      this.submenus.push({
        label: "Grupos",
        icon: 'pi pi-users',
        command: () => this.router.navigate(['manutencao-grupo'])
      });

      this.submenus.push({
        label: "Usuários",
        icon: 'pi pi-user',
        command: () => this.router.navigate(['manutencao-usuario'])
      });

    }

    this.submenus.push({
      label: "Processamento",
      icon: 'pi pi-desktop',
      command: () => this.router.navigate(['manutencao-processamento'])

    });

    this.submenus.push({
      label: "Abrir novo ticket (Ocorrência)",
      icon: 'pi pi-exclamation-triangle',
      command: () => {
        window.open('https://forms.monday.com/forms/2f0fe5da369d1aade97c123fdea98ee2?r=use1', '_blank');
      }
    });

    this.submenus.push({
      label: "Ajuda",
      icon: 'pi pi-question-circle',
      url: 'ajuda'
    });

    this.items = [{
      label: "",
      icon: 'pi pi-bars',
      items: this.submenus
    }]

  }

  logout() {
    this.auth.destroySession();
    this.router.navigateByUrl('login');
  }
}
