import { Component, NgModule } from '@angular/core';

@Component({
    selector: 'app-ajuda',
    templateUrl: './ajuda.component.html',
    styleUrls: ['./ajuda.component.css']
  })

  export class AjudaComponent {


}



