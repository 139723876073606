<app-menu-bar></app-menu-bar>
<div class="empresa-container">
    <div style="text-align: center">Configuração de Código de Empresas</div>
        <p-toolbar>
        <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
                <p-toast></p-toast>
            </div>
        </ng-template>
        </p-toolbar>
        <div class="table-container">
            <p-table [value]="empresas" styleClass="p-datatable-gridlines"
                [tableStyle]="{ 'min-width': '50rem' }"
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                editMode="row"
                [loading]="loading"
                [paginator]="true"
                [rows]="15"
                [rowsPerPageOptions]="[10, 15, 20]"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"      
                [globalFilterFields]="[
                'dados.id',
                'dados.codCia',
                'dados.codEmpresa']">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="id">
                            <div class="flex justify-content-between align-items-center">
                                Id
                                <div class="flex justify-content-between align-items-center">
                                    <p-sortIcon field="id"></p-sortIcon>
                                    <p-columnFilter field="id" display="menu"></p-columnFilter>
                                </div> 
                            </div>
                        </th>
                        <th pSortableColumn="codCia">
                            <div class="flex justify-content-between align-items-center">
                                Código Companhia (TCO)
                                <div class="flex justify-content-between align-items-center">
                                    <p-sortIcon field="codCia"></p-sortIcon>
                                    <p-columnFilter field="codCia" display="menu"></p-columnFilter>
                                </div> 
                            </div>                            
                        </th>
                        <th pSortableColumn="nomeCia">
                            <div class="flex justify-content-between align-items-center">
                                Nome Companhia (TCO)
                                <div class="flex justify-content-between align-items-center">
                                    <p-sortIcon field="nomeCia"></p-sortIcon>
                                    <p-columnFilter field="nomeCia" display="menu"></p-columnFilter>
                                </div> 
                            </div>                            
                        </th>
                        <th pSortableColumn="codEmpresa">
                            <div class="flex justify-content-between align-items-center">
                                Código Empresa (safx)
                                <div class="flex justify-content-between align-items-center">
                                    <p-sortIcon field="codEmpresa"></p-sortIcon>
                                    <p-columnFilter field="codEmpresa" display="menu"></p-columnFilter>
                                </div> 
                            </div>
                        </th>
                        <th pSortableColumn="grupoContabil">
                            <div class="flex justify-content-between align-items-center">
                                Grupo Contábil
                                <div class="flex justify-content-between align-items-center">
                                    <p-sortIcon field="codEmpresa"></p-sortIcon>
                                    <p-columnFilter field="codEmpresa" display="menu"></p-columnFilter>
                                </div> 
                            </div>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dados let-editing="editing">
                    <tr [pEditableRow]="empresa">
                        <td style="text-align: center">{{ dados.id }}</td>
                        <td style="text-align: left">{{ dados.codCia }}</td>
                        <td style="text-align: left">{{ dados.nomeCia }}</td>
                        <td [pEditableColumn]="editing" style="text-align: left">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText [(ngModel)]="dados.codEmpresa" required>
                                    <small class="p-error" *ngIf="submitted && !dados.codEmpresa">
                                      Empresa (safx) é obrigatória.
                                    </small>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{dados.codEmpresa}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [pEditableColumn]="editing" style="text-align: left">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText [(ngModel)]="dados.grupoContabil" required>
                                    <small class="p-error" *ngIf="submitted && !dados.grupoContabil">
                                        Grupo Contábil é obrigatório.
                                    </small>          
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{dados.grupoContabil}}
                                </ng-template>
                            </p-cellEditor>                        
                        </td>
                        <td style="text-align: center">
                            <i *ngIf="dados.id" class="pi pi-building" sytle="collor: gray" pTooltip="Estabelecimentos" (click)="abreEstabelecimentos(dados)"></i>
                        </td>
                        <td>
                            <div class="flex align-items-center justify-content-center gap-2">
                                <i class="pi pi-check" style="color: green" (click)="onRowEditSave(dados)" pTooltip="Aplicar"></i>
                                <i class="pi pi-refresh" style="color: orange" (click)="onRowEditCancel()" pTooltip="Reverter"></i> 
                                <i class="pi pi-trash" style="color: red" (click)="remover(dados)" pTooltip="Limpar"></i>    
                                <i class="pi pi-info-circle" style="color: blue" [pTooltip]="getToolTipInfo(dados)"></i> &nbsp;
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <p-confirmDialog appendTo="body" icon="pi pi-exclamation-triangle" 
                [position]="'top'" [baseZIndex]="10000">
            </p-confirmDialog>

            <p-dialog header="Configuração de Códigos de Estabelecimento" [(visible)]="visible"
                [style]="{ width: '1200px' }"
                [modal]="true"
                (onHide)="hideDialog()"
                styleClass="p-fluid">
                <p-table [value]="estabelecimentos" styleClass="p-datatable-gridlines"
                    [tableStyle]="{ 'min-width': '50rem' }"
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    editMode="row"
                    [loading]="loading"
                    [globalFilterFields]="[
                    'dados.id',
                    'dados.codContribuinte',
                    'dados.nomeContribuinte',
                    'dados.codEstabelecimento']">

                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="id">
                                <div class="flex justify-content-between align-items-center">
                                    Id
                                    <div class="flex justify-content-between align-items-center">
                                        <p-sortIcon field="id"></p-sortIcon>
                                        <p-columnFilter field="id" display="menu"></p-columnFilter>
                                    </div> 
                                </div>
                            </th>
                            <th pSortableColumn="codContribuinte">
                                <div class="flex justify-content-between align-items-center">
                                    Contribuinte
                                    <div class="flex justify-content-between align-items-center">
                                        <p-sortIcon field="codContribuinte"></p-sortIcon>
                                        <p-columnFilter field="codContribuinte" display="menu"></p-columnFilter>
                                    </div> 
                                </div>                            
                            </th>
                            <th pSortableColumn="nomeContribuinte">
                                <div class="flex justify-content-between align-items-center">
                                    Nome
                                    <div class="flex justify-content-between align-items-center">
                                        <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                                        <p-columnFilter field="nomeContribuinte" display="menu"></p-columnFilter>
                                    </div> 
                                </div>                            
                            </th>
                            <th pSortableColumn="codEstabelecimento">
                                <div class="flex justify-content-between align-items-center">
                                    Código Estabelecimento (SAFX)
                                    <div class="flex justify-content-between align-items-center">
                                        <p-sortIcon field="codEstabelecimento"></p-sortIcon>
                                        <p-columnFilter field="codEstabelecimento" display="menu"></p-columnFilter>
                                    </div> 
                                </div>                            
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-estabs let-editing="editing">
                        <tr [pEditableRow]="estabelecimento">
                            <td style="text-align: center">{{ estabs.id }}</td>
                            <td style="text-align: left">{{ estabs.codContribuinte }}</td>
                            <td style="text-align: left">{{ estabs.nomeContribuinte }}</td>
                            <td [pEditableColumn]="editing" style="text-align: left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText [(ngModel)]="estabs.codEstabelecimento" required>
                                        <small class="p-error" *ngIf="submitted && !estabs.codEstabelecimento">
                                          Estabelecimento (safx) é obrigatório.
                                        </small>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{estabs.codEstabelecimento}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <div class="flex align-items-center justify-content-center gap-2">
                                    <i class="pi pi-check" style="color: green" (click)="onRowEditSaveEstab(estabs)" pTooltip="Aplicar"></i>
                                    <i class="pi pi-refresh" style="color: orange" (click)="onRowEditCancelEstab()" pTooltip="Reverter"></i> 
                                    <i class="pi pi-trash" style="color: red" (click)="removerEstab(estabs)" pTooltip="Limpar"></i>    
                                </div>
                            </td>    
                        </tr>
                    </ng-template>
                </p-table>
            </p-dialog>
        </div>        
</div>

