<app-menu-bar></app-menu-bar>
<div class="usuario-container">
  <div style="text-align: center">Manutenção de Usuários</div>
  <p-toolbar>
    <ng-template pTemplate="right">
      <div class="card flex justify-content-center">
        <p-toast></p-toast>
      </div>
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        label="Adicionar"
        class="p-button-info mr-2"
        (click)="add()"
      ></button>
    </ng-template>
  </p-toolbar>

  <div class="table-container">
    <p-table
      [value]="usuarios"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [rows]="15"
      [rowsPerPageOptions]="[10, 15, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"      
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
      [globalFilterFields]="[
        'usuario.nome',
        'usuario.cadastro',
        'usuario.status',
        'usuario.acesso',
        'usuario.grupo',
        'usuario.email',
        'usuario.telefone'
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nome">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Nome
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="nome"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="nome"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="cadastro">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Data de Cadastro
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="amount"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="cadastro"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="statusDescricao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="statusDescricao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="statusDescricao"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="acessoDescricao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Acesso
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="acessoDescricao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="acessoDescricao"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>


          <th pSortableColumn="grupo.nome">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Grupo
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="grupo.nome"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="grupo.nome"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>          

          <th pSortableColumn="email">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                E-mail
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="email"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="email"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="telefone">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Telefone
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="telefone"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="telefone"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>          
          <th style="text-align: center">Operação</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-usuario>
        <tr>
          <td style="text-align: center">{{ usuario.nome }}</td>
          <td style="text-align: center">{{ usuario.cadastro | date:'dd/MM/yyyy'}}</td>
          <td style="text-align: center">{{ usuario.statusDescricao }}</td>
          <td style="text-align: center">{{ usuario.acessoDescricao }}</td>
          <td style="text-align: center">{{ usuario.grupo.nome }}</td>
          <td style="text-align: center">{{ usuario.email }}</td>
          <td style="text-align: center">{{ usuario.telefone | phone }}</td>
          <td style="text-align: center">
            <p-button
              icon="pi pi-file-edit"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Editar"
              (click)="editarUsuario(usuario)"
            >
            </p-button>
            <p-button
              icon="pi pi-trash"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Apagar"
              (click)="deletarUsuario(usuario)"
            >
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-confirmDialog
      appendTo="body"
      icon="pi pi-exclamation-triangle"
      [position]="'top'"
      [baseZIndex]="10000"
    ></p-confirmDialog>

    <p-dialog
      header="Cadastro de usuario"
      [style]="{ width: '750px' }"
      [modal]="true"
      [(visible)]="visible"
      (onHide)="hideDialog()"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h2 *ngIf="usuario.id">Editar Usuário</h2>
        <h2 *ngIf="!usuario.id">Novo Usuário</h2>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="grid">
          <div class="p-field col-6 p-fluid">
            <label for="usuario.nome">Nome Usuário: </label>
            <input
              type="text"
              autocomplete="off"
              pInputText
              id="usuario.nome"
              [(ngModel)]="usuario.nome"
            />
            <small class="p-error" *ngIf="submitted && !usuario.nome"
              >Nome é obrigatório.</small
            >
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.senha">Senha: </label>
            <input
              type="password"
              autocomplete="off"
              pInputText
              id="usuario.senha"
              [(ngModel)]="usuario.senha"
              autocomplete="current-password"
              required
            />
            <small class="p-error" *ngIf="submitted && !usuario.senha"
            >Senha é obrigatória.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.cadastro">Cadastro: </label>
            <input
              type="date"
              autocomplete="off"
              pInputText
              id="usuario.cadastro"
              [(ngModel)]="usuario.cadastro"
              disabled
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.status">Status: </label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              [options]="statusUsuario"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="usuario.status"
            >
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !usuario.status">
              Status é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.acesso">Acesso: </label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              [options]="tipoAcesso"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="usuario.acesso"
            >
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !usuario.acesso">
              Tipo de Acesso é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.grupo">Grupo: </label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              [options]="grupos"
              optionLabel="nome"
              (onChange)="grupoSelecionado($event)"
              [(ngModel)]="usuario.grupo"
            >
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !usuario.grupo"
              >Grupo é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.email">E-mail: </label>
            <input
              type="text"
              autocomplete="off"
              pInputText
              id="usuario.email"
              [(ngModel)]="usuario.email"
            />
            <small class="p-error" *ngIf="submitted && !usuario.email"
            >Email é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="usuario.telefone">Telefone: </label>
            <input
              type="number"
              autocomplete="off"
              pInputText
              id="usuario.telefone"
              [(ngModel)]="usuario.telefone"
            />
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button
        pButton
        label="{{ usuario.id ? 'Atualizar' : 'Salvar' }}"
        icon="pi pi-check"
        class="b-salvar"
        (click)="salvarUsuario()"
      ></button>
        <p-button
          pRipple
          label="Cancelar"
          icon="pi pi-times"
          class="b-cancelar"
          styleClass="p-button-secondary"
          (click)="hideDialog()"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
