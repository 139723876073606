import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguracaoCodigoEmpresas } from '../modelo/ConfiguracaoCodigoEmpresas';

@Injectable({
    providedIn: 'root'
  })
export class ConfiguracaoCodigoEmpresasService {

    apiUrl: string = `${environment.backendUrl}/configuracao/codigo-empresas`;

    constructor(private http: HttpClient) { }

    getEmpresasAll (pageNumber: number, pageSize: number){
        let searchParams = new HttpParams();

        searchParams = searchParams.append(`page`, String(pageNumber));
        searchParams = searchParams.append(`size`, String(pageSize));

        return this.http.get<IApiResponse>(`${this.apiUrl}`,
        { params: searchParams });
      }

      salvar(registro: ConfiguracaoCodigoEmpresas): Observable<any> {
        return this.http.post(`${this.apiUrl}`, registro );
      }


      editar(registro: ConfiguracaoCodigoEmpresas): Observable<void>{
        return this.http.put<void>(`${this.apiUrl}/${registro.id}`, registro )
      }

      remover(id: number | undefined): Observable<any> {
        return this.http.delete<void>(`${this.apiUrl}/${id}` )
      }



}
