import { Usuario } from './../modelo/Usuario';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IApiResponse } from '../modelo/PageProps';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  apiUrl: string = `${environment.backendUrl}/usuarios`;

  constructor(private http: HttpClient) { }

  getUsuarios(pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<IApiResponse>(`${this.apiUrl}`,{ params: searchParams });
  }

  salavarUsuarios(usuario: Usuario): Observable<any> {
    return this.http.post(`${this.apiUrl}`, usuario);
  }

  editarUsuarios(usuario: Usuario): Observable<any> {
    return this.http.put(`${this.apiUrl}/${usuario.id}`, usuario);
  }

  deletarUsuario(usuario: Usuario): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${usuario.id}`);
  }
}
